import React, { useState, useEffect } from "react";

import './nutriplus.css';

import KikaNutri from '../resources/imgThree.png';
import Recipe from "./recipe";
import Loading from "../components/loading";

import { backendURL } from "../backendURL";

function NutriPlus(){

    const [recipes, setRecipes] = useState([]);

    const fetchData = async () => {
		try {
			let request = new FormData();
			request.append('key', 'Recipes');
			const questionsResponse = await fetch(backendURL, {
				method: 'POST',
				headers: {
				},
				body: request,
			});
			const response = await questionsResponse.json();
			if(response.msg === 'fail'){
				if(response.data === 'no-data-found'){
					throw('NO DATA IN FILE!');
				}else if(response.data === 'file-error'){
					throw('NO FILE FOUND!');
				}else if(response.data === `bad-key`){
					throw('Bad key request!');
				}else{
					throw('UNKNOWN!');
				}
			}else if(response.msg === 'success'){
				setRecipes(response.data);

			}

		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
  
    useEffect(() => {
		fetchData();
	}, []);

    return(
        <div className="nutriplus-page">
            <Loading promise={fetchData}/>
            <div className="nutriplus-blend"></div>
            <h1>NUTRIPLUS</h1>
            <h2>IZ LJUBAVI PREMA ZDRAVOM <span className="life-span"> ŽIVOTU!</span></h2>
            <div className="nutriplus-content">
                <h3>
                    NUTRIPLUS predstavlja inovativnu liniju visokokvalitetnih suplemenata koje nudi renomirana FARMASI kompanija. Ovi suplementi su dizajnirani kako bi podržali optimalno funkcionisanje organizma, pružajući esencijalne hranljive materije koje mogu nedostajati u svakodnevnoj ishrani. Bez obzira da li je u pitanju dopuna vitamina, prirodni sejkovi, čajevi za mršavljenje i detoksikaciju, kolagen, osvežavajući aloe napitak ili multivitamini prilagođeni ženama, NUTRIPLUS predstavlja pouzdanog partnera u održavanju zdravog i balansiranog života.
                </h3>
                <img className="nutri-kika" src={KikaNutri} alt="nutri-kika" />
            </div>
            <a className="nutri-book" href="https://www.farmasi.rs/index.php/nutriplus/nutriplus-brosura#book/" target="_blank" rel="noreferrer">POGLEDAJTE BROŠURU</a>
            <div className="nutri-recipes-sec">
                <h1>RECEPTI ZA NUTRIPLUS ŠEJK</h1>
                <div className="nutri-recipes-content">
                    <div className="nutri-recipes">
                        {recipes.map((el,index) => (
                            <Recipe id={`idr${index}`} key={`r${index}`} r={el} />
                        ))}
                    </div>
                    <div className="instructions">
                    <ul >
                        INSTRUKCIJE:
                        <h3>Dodavanje Sastojaka:</h3>
                        <li>Dodajte tečne sastojke (voda, mleko) prvo, a zatim dodajte NutriPlus šejk i ostale sastojke.Ako koristite led, možete ga dodati zajedno sa svim sastojcima.</li>

                        <h3>Blendiranje:</h3>
                        <li>Blendajte na visokoj brzini kako biste postigli glatku teksturu.
                        Ako primetite da je smesa pregusta, možete dodati malo više tečnosti po potrebi.</li>

                        <h3>Eksperimentišite:</h3>
                        <li>Ovo su recepti koje sam sama sastavila po mom ukusu i potrebama! Slobodno eksperimentišite sa sastojcima i njihovim količinama kako biste pronašli kombinacije koje vam najviše odgovaraju.</li>

                        <h3>Prilagodite Ukus:</h3>
                        <li>Ako želite slađi ukus, dodajte med, sirup od agave ili neki drugi zaslađivač po ukusu.
                        Ako želite kremastiju teksturu, dodajte jogurt, orašaste plodove ili bananu.</li>

                        <h3>Serviranje:</h3>
                        <li>Poslužite šejkove odmah nakon blendiranja da biste zadržali sveže sastojke i najbolji ukus.
                        Zapamtite, recepti su fleksibilni, pa slobodno prilagodite sastojke prema svom ukusu i preferencijama. Uživajte u zdravim i ukusnim NutriPlus šejkovima!</li>
                    </ul>
                    </div>
                </div>
            </div>
            <div className="nutriplus-blend"></div>
        </div>
    )

}
export default NutriPlus;