import React from "react";

import './recipe.css';

function Recipe({r,id}){
    return(
        <ul key={id} className="nutri-recipe">
            <h3 className="nutri-recipe-title">{r.name}</h3>
            SASTOJCI:
            <ul>
                {r.ingredients.map((el ,index) => (
                    <li key={`${r.name}ing${index}`}>{el}</li>
                ))}
            </ul>
        </ul>
    )
}

export default Recipe;