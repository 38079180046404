import './App.css';
import React from 'react';

import Nav from './components/nav';

import { BrowserRouter as Router , Route, Routes} from 'react-router-dom';

import Home from './Home/home'

import Shop from './Shop/Shop';

import Footer from './components/footer';

import NotFound from './NotFound/NotFound'

import Membership from './Membership/Membership';

import Contact from './contact/contact';

import FAQ from './FAQ/FAQ';

import NutriPlus from './nutriplus/nutriplus';
import Loading from './components/loading';

function App() {

	return (
		<Router>
			<Loading/>
			<div className="App">
				<Nav/>
			<Routes>
				<Route key={'path1'} path='/Home' element={<Home/>}/>
				<Route key={'path2'} path='/Shop' element={<Shop/>}/>
				<Route key={'path3'} path='/Farmasi-klub' element={<Membership/>}/>
				<Route key={'path4'} path='/NutriPlus' element={<NutriPlus/>}/>
				<Route key={'path5'} path='/NutriPlus/Recepti' element={<Home/>}/>
				<Route key={'path6'} path='/Kontakt' element={<Contact/>}/>
				<Route key={'path7'} path='/ČestaPitanja' element={<FAQ/>}/>
				<Route key={'path8'} path='/404' element={<NotFound />}></Route>
			</Routes>
			<Footer/>
			</div>
		</Router>
	);
}

export default App;
