import React from "react";

import './homeheading.css';

import FramasiLOGO from '../resources/Farmasi-Logo.png';
import KristinaLogo from '../resources/Kristina-Logo.png';

import IMGONE from '../resources/imgOne.png';
import IMGTWO from '../resources/imgTwo.png';
import IMGTHREE from '../resources/imgThree.png';

function HomeHeading(){
    return(
        <div className="home-heading">
            <div className="background-image-container">
                <img src={IMGONE} alt="cover-img"/>
                <img src={IMGTWO} alt="cover-img"/>
                <img src={IMGTHREE} alt="cover-img"/>
            </div>
            <div className="home-heading-headers">
                <img className="kriss-logo" src={KristinaLogo} alt="kriss-logo"/>
                <h1>Dobrodošli na moj <a href="https://www.farmasi.rs/" target="_blank" rel="noreferrer" className="logo-farmasi-link"><img src={FramasiLOGO} alt="farmasi-logo"></img></a> svet!</h1>
            </div>
        </div>
    )
}

export default HomeHeading;