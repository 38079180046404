import './nav.css'
import LogoIcon from '../resources/LogoIcon.png'
import { Link } from "react-router-dom";
import React , {useState, useRef, useEffect} from 'react';

import BurgerButton from './burgerButton';



function Nav (){

    const [Expanded,setExpanded] = useState(false);
    const [Selected, setSelected] = useState('/Home');
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
	
    const menuRef = useRef(null);

    const pathNames = ['/','/Home', '/Shop', '/Farmasi-klub', '/NutriPlus', '/Kontakt', '/ČestaPitanja', '/404'];

    function handleBurgerMenu() {
        // Toggle the state variable
        setExpanded(!Expanded);
    }

    const handleResize = () => {
        let newWidth = window.innerWidth;
        setScreenWidth(window.innerWidth);
        console.log(newWidth);
        newWidth > 789? setExpanded(true) : setExpanded(false);

    };
    
    useEffect(() => {
        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize);
    
        // Optionally, you can also remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        // Access the DOM element using the ref
        const myElement = menuRef.current;
        // Modify the height of the element based on its scroll height
        if (myElement) {
          myElement.style.height = Expanded ? `${myElement.scrollHeight + 10}px` : '0px';
        }
    },[Expanded]);

    useEffect(() => {
        if(screenWidth > 540){
            setExpanded(true);
        }
        if(window.location.pathname === "/"){
            window.location.assign('/Home');
        }else if(!pathNames.includes(window.location.pathname)){
            window.location.assign('/404');
        }
        setSelected(window.location.pathname);
    }, [])

    

    return( 
        <div className="nav">
            <img className="nav-logo" src={LogoIcon} alt="logo" />
            <BurgerButton className={Expanded ? 'expanded' : ''} onClick={() => handleBurgerMenu()}></BurgerButton>
            <nav className={Expanded ? 'nav-pages' : 'nav-pages hidden'} id="nav-menu" ref={menuRef}>
                <Link key={'link13'} className={(!Expanded ? 'nav-link hidden' : 'nav-link') + (Selected === '/Home' ? ' selected' : '')} onClick={() => setSelected('/Home')} to='/Home'>POCETNA</Link>
                <Link key={'link3'} className={(!Expanded ? 'nav-link hidden' : 'nav-link') + (Selected === '/Shop' ? ' selected' : '')} onClick={() => setSelected('/Shop')} to='/Shop'>SHOP</Link>
                <Link key={'link1'} className={(!Expanded ? 'nav-link hidden' : 'nav-link') + (Selected === '/Farmasi-klub' ? ' selected' : '')} onClick={() => setSelected('/Farmasi-klub')} to='/Farmasi-klub'>KLUB</Link>
                <Link key={'link43'} className={(!Expanded ? 'nav-link hidden' : 'nav-link') + (Selected === '/NutriPlus' ? ' selected' : '')} onClick={() => setSelected('/NutriPlus')} to='/NutriPlus'>NUTRIPLUS</Link>
                <Link key={'link11'} className={(!Expanded ? 'nav-link hidden' : 'nav-link') + (Selected === '/Kontakt' ? ' selected' : '')} onClick={() => setSelected('/Kontakt')} to='/Kontakt'>KONTAKT</Link>
                <Link key={'link61'} className={(!Expanded ? 'nav-link hidden' : 'nav-link') + (Selected === '/ČestaPitanja' ? ' selected' : '')} onClick={() => setSelected('/ČestaPitanja')} to='/ČestaPitanja'>ČESTA PITANJA</Link>
            </nav>
        </div>
    )
}

export default Nav;