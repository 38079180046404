import React from "react";

import './footer.css';

import LogoIcon from '../resources/LogoIcon.png';

import SocialIcon from "./SocialIcon";

import InstaSVG from '../resources/Instagram.svg';

import TikTokSVG from '../resources/TikTok.svg';

import MaricIcon from '../resources/MaricIcon.png';

import FarmasiLogo from '../resources/Farmasi-Logo.png';

function Footer(){
    return(
        <footer>
            <div className="footer-section">
                <SocialIcon href={'https://www.instagram.com/farmasi_kriss.petronijevic/'} imgSrc={InstaSVG} name={'@farmasi_kriss.petronijevic'}/>
                <SocialIcon href={'https://www.tiktok.com/@farmasi_kiki_kriss_'} imgSrc={TikTokSVG} name={'@farmasi_kiki_kriss_'}/>
            </div>
            <div className="footer-icon-container">
                <img className="footer-icon" src={LogoIcon} alt="logo-icon"/>
                <span>COPYRIGHT © Kristina Petronijević</span> 
                <span>Sva prava zadržana</span>
            </div>
            <div className="footer-section">
                <div className="creator-credit">
                    Omogućeno zahvaljujući:
                    <a href="https://www.farmasi.rs/" target="_blank" rel="noreferrer" className="logo-farmasi-link"><img src={FarmasiLogo} alt="farmasi-logo"/></a>
                    Web stranica napravljena od strane:
                    <a href="https://vukmaric.rs" target="_blank" rel="noreferrer" className="footer-link">
                    <img className="maric-icon" alt="maric-logo" src={MaricIcon}/>
                    VUK MARIĆ
                    <div className="link-stripe"></div></a>
                </div>
            </div>
        </footer>
    )
}

export default Footer;