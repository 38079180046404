import React from 'react';

import './MemberInstruction.css'

function RegistrationExplanation() {
    return (
        <div className='membership-instructions'>
            <h2>Kako da postanete član Farmasi kluba preko Kristina Farmasi Websajta</h2>
            <h3>Korak 1: Pošaljete Zahtev Preko Kristina Farmasi Websajta</h3>
            <ol>
                <li>Na dnu stranice, pronađite opciju "Pošaljite zahtev"</li>
                <li>Unesite email adresu i pošaljete zahtev.</li>
            </ol>
            <h3>Korak 2: Potvrda Registracije</h3>
            <ol>
                <li>Nakon slanja zahteva, dobijate mejl da je zahtev poslat i Kristina će obraditi vašu registraciju.</li>
                <li>U roku od nekoliko sati, trebali biste primiti email od Farmasi tima sa detaljima o registraciji.</li>
            </ol>
            <h3>Korak 3: Prva Porudžina</h3>
            <ol>
                <li>Kada dobijete email sa informacijama o registraciji, pratite upute za prvu porudžbinu.</li>
                <li>Imajte na umu da ćete imati određeni rok od 72 sata  da napravite svoju prvu porudžinu kao član kluba. Ovo je važno za aktivaciju članstva.</li>
                <li>Izaberite proizvode koje želite naručiti za svoju prvu porudžinu i pratite upute za plaćanje i dostavu.</li>
            </ol>
            <h3>Korak 4: Clanski Broj</h3>
            <ol>
                <li>Nakon što obavite prvu porudžinu u okviru određenog roka, vaša registracija će biti potvrđena, a dobićete svoj članski broj od Farmasi kluba.</li>
                <li>Ovaj članski broj možete koristiti za pristup različitim članovima kluba, beneficijama i praćenju vašeg članstva.</li>
            </ol>
            <h3>Korak 5: Kristina Farmasi - Vaš Lider i Izvor Saveta</h3>
            <ol>
                <li>Registracijom preko Kristina Farmasi Websajta, postajem vaš lider i izvor podrške.</li>
                <li>Tu sam da vam pružim sve informacije koje su vam potrebne, od detalja o proizvodima i kako ih koristiti do saveta o poslovanju unutar Farmasi kluba.</li>
                <li>Slobodno me kontaktirajte putem kontakt informacija koje ćete primiti nakon registracije ili ako niste sigurni, u vezi registracije i članstva, putem web sajta. Tu sam da odgovorim na vaša pitanja i pružim vam sve neophodne informacije i podršku.
                </li>
            </ol>
        </div>
    )
}

export default RegistrationExplanation;