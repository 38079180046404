import React , {useState, useEffect} from "react";
import { Link } from "react-router-dom";

import './FAQ.css';
import Loading from "../components/loading";

import { backendURL } from "../backendURL";

function FAQ(){

    const [questions, setQuestions] = useState([]);
  
    const fetchData = async () => {
		try {
			let request = new FormData();
			request.append('key', 'FAQ');
			const questionsResponse = await fetch(backendURL, {
				method: 'POST',
				headers: {
				},
				body: request,
			});
			const response = await questionsResponse.json();
			if(response.msg === 'fail'){
				if(response.data === 'no-data-found'){
					throw('NO DATA IN FILE!');
				}else if(response.data === 'file-error'){
					throw('NO FILE FOUND!');
				}else if(response.data === `bad-key`){
					throw('Bad key request!');
				}else{
					throw('UNKNOWN!');
				}
			}else if(response.msg === 'success'){
				setQuestions(response.data);

			}

		} catch (error) {
			console.error("Error fetching data:", error);
		}
	};
  
    useEffect(() => {
		fetchData();
	}, []);

    return (
        <div className="faq-page">
            <h1>ČESTA PITANJA</h1>
            <ul>
                {questions.map((el,index) => (
                    <li key={`q${index}`}>Pitanje {index+1}
                        <ul>
                            <li>{el.q}</li>
                            <li>{el.a}</li>
                        </ul>
                    </li>
                ))}
            </ul>
            <h3>Za dodatna pitanja kontaktirajte me putem websajta na stranici <Link to={'/Kontakt'}>KONTAKT</Link>.</h3>
        </div>
    )
}

export default FAQ;