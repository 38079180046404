import React, {useState, useEffect} from "react";

import './contact.css';

import { backendURL } from "../backendURL";

import LOGO from '../resources/LogoIcon.png';

function Contact(){

    const [userInput, setUserInput] = useState({fname: '', lname: '', email: '', msg: ''});
    const [sendingEmail, setSendingEmail] = useState({sending: false, sent: false, failed: false});

    const handleUserInput = (e) => {
        const { name, value } = e.target;
        setUserInput(prevState => ({ ...prevState, [name]: value }));

    }

    const sendEmail = async () => {
        window.scrollTo(0,0);
        setSendingEmail({sending: true, sent: false, failed: false});
		try {
			let request = new FormData();
			request.append('key', 'ContactEmail');
            request.append('package', JSON.stringify(userInput));
			const questionsResponse = await fetch(backendURL, {
				method: 'POST',
				headers: {
				},
				body: request,
			});
            if (!questionsResponse.ok) {
                console.error("Server returned an error:", questionsResponse.statusText);
            } else {
                // Handle the JSON response
                const response = await questionsResponse.json();
                if(response.msg === 'fail'){
                    const error = response.data;
                    throw(error);
                }else if(response.msg === 'success'){
                    setSendingEmail({sending: false, sent: true, failed: false});
                    
                }
            }

		} catch (error) {
			console.error("Error fetching data:", error);
            setSendingEmail({sending: false, sent: false, failed: true});
		}
        setUserInput({fname: '', lname: '', email: '', msg: ''});
	};

    return(
        <div className="contact-page">
            {sendingEmail.sending? (
                <div className="loading-comp">
                    <div>
                        <img src={LOGO} alt="logo"/>
                        <p>SLANJE</p>
                    </div>
                </div>
            ) : sendingEmail.failed? (
                <div className="loading-comp">
                    <div>
                        <img className="no-rotate" src={LOGO} alt="logo"/>
                        <p>DOŠLO JE DO GREŠKE!</p>
                        <button className="loading-comp-button" onClick={()=>setSendingEmail({sending: false, sent: false, failed: false})}>OK</button>
                    </div>
                </div>
            ) :sendingEmail.sent? (
                <div className="loading-comp">
                    <div>
                        <img className="no-rotate" src={LOGO} alt="logo"/>
                        <p>USPEŠNO! Očekujte odgovor uskoro!</p>
                        <button className="loading-comp-button" onClick={()=>setSendingEmail({sending: false, sent: false, failed: false})}>OK</button>
                    </div>
                </div>
            ) : (
                <form className="contact-form">
                    <h1>Kontaktirajte me!</h1>
                    <label htmlFor="fname">IME: <input id="fname" name="fname" onChange={handleUserInput} value={userInput.fname}  /></label>
                    
                    <label htmlFor="lname">PREZIME: <input id="lname" name="lname" onChange={handleUserInput} value={userInput.lname} /></label>
                    
                    <label htmlFor="email">EMAIL: <input id="email" name="email" onChange={handleUserInput} value={userInput.email} /></label>
                    
                    <label htmlFor="msg">PORUKA: <textarea id="msg" name="msg" onChange={handleUserInput} value={userInput.msg} /></label>
                    
                    <button onClick={sendEmail}>POŠALJI</button>
                </form>
            )}
        </div>
    )
}

export default Contact;