import React from "react";

import './homecontents.css'

import LogoIcon from '../resources/LogoIcon.png';

import CERT from '../resources/DiplomaKika.jpg';

function HomeContents(){
    return(
        <div className="home-contents">
                <div className="intro-paragraph">
                    <p>Moje ime je <span className="intro-name">Kristina Petronijevic</span> i sa ponosom predstavljam Farmasi.</p>
                    <p>Kao član Farmasi tima, imam priliku da vam ponudim jedinstvenu šansu da postanete deo ove fantastične zajednice.</p>
                </div>
                <h2 className="home-contents-title">Šta možete očekivati od mog Farmasi tima?</h2>
                <p className="home-contents-p">
                    <a className="p-link" href="/Farmasi-klub">
                        <img className="list-dot-logo" src={LogoIcon} alt="logo-icon"/>
                        ČLANSTVO
                    </a>
                    Postanite deo Farmasi porodice i iskoristite brojne pogodnosti koje vam nudimo.
                </p>
                <p className="home-contents-p">
                    <a className="p-link" target="_blank" rel="noreferrer" href="https://www.farmasi.rs/index.php/najpopularniji">
                        <img className="list-dot-logo" src={LogoIcon} alt="logo-icon"/>
                        AUKTUELNI PROIZVODI
                    </a>
                    Pregledajte našu paletu proizvoda visokog kvaliteta koji će vam pomoći da brinete o svom zdravlju i lepoti.
                </p>
                <p className="home-contents-p">
                    Naručite proizvode na:
                    <a className="p-link" href='/Shop'>
                        <img className="list-dot-logo" src={LogoIcon} alt="logo-icon"/>
                        SHOP
                    </a>
                    Ovde ćete pronaći katalog sa najnovijim proizvodima za negu kože, kozmetiku visokog kvaliteta i mnogo toga što će vam pomoći da zablistate.
                </p> 
                <img className="kika-img" src={CERT} alt="kika-img"/>
                <p className="home-contents-p last">
                    Hvala vam što ste posetili moju stranicu, i radujemo se što ćemo zajedno deliti strast prema Farmasi proizvodima i pružiti vam besprekoran servis.
                </p>
        </div>
    );
}

export default HomeContents;