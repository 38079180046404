import React from 'react';

import './invoice.css';

const Invoice = ({ invoiceNumber, invoiceDate, customerInfo, items, totalAmount }) => {

  return (
    <div className='invoice-box' style={{ backgroundColor: '#ffebcd', fontFamily: 'Open Sans, sans-serif', fontSize: '100%', fontWeight: 400, lineHeight: 1.4, color: '#000' }}>
      <table className='invoice' style={{ width: '100%', maxWidth: '670px', margin: '50px auto 10px', backgroundColor: '#fff', padding: '50px', boxShadow: '0 1px 3px rgba(0,0,0,.12), 0 1px 2px rgba(0,0,0,.24)', borderTop: 'solid 10px #ff5a68' }}>
        <thead>
          <tr>
            <th style={{ textAlign: 'left' }}><img style={{ width: '100%', maxWidth: '200px' }} src="https://kristinafarmasi.rs/backend/Kristina-Logo.png" alt="Bachana Tours" /></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td style={{ height: '35px' }}></td>
          </tr>
          <tr>
            <td colSpan="2" style={{ border: 'solid 1px #ddd', padding: '10px 20px' }}>
              <p style={{ fontSize: '14px', margin: '0 0 6px 0' }}><span style={{ fontWeight: 'bold', display: 'inline-block', minWidth: '146px' }}>Broj računa:</span> {invoiceNumber}</p>
			  <p style={{ fontSize: '14px', margin: '0 0 6px 0' }}><span style={{ fontWeight: 'bold', display: 'inline-block', minWidth: '146px' }}>Datum:</span> {invoiceDate}</p>
              <p style={{ fontSize: '14px', margin: '0 0 0 0' }}><span style={{ fontWeight: 'bold', display: 'inline-block', minWidth: '146px' }}>Vrednost:</span> RSD {totalAmount.toFixed(2)}</p>
            </td>
          </tr>
          <tr>
            <td style={{ height: '35px' }}></td>
          </tr>
          <tr>
            <td style={{ width: '50%', padding: '20px', verticalAlign: 'top' }}>
              <p style={{ margin: '0 0 10px 0', padding: '0', fontSize: '14px' }}><span style={{ display: 'block', fontWeight: 'bold', fontSize: '13px' }}>Ime</span> {customerInfo.name}</p>
              <p style={{ margin: '0 0 10px 0', padding: '0', fontSize: '14px' }}><span style={{ display: 'block', fontWeight: 'bold', fontSize: '13px' }}>Email</span> {customerInfo.email}</p>
              <p style={{ margin: '0 0 10px 0', padding: '0', fontSize: '14px' }}><span style={{ display: 'block', fontWeight: 'bold', fontSize: '13px' }}>Telefon</span> {customerInfo.phone}</p>
              <p style={{ margin: '0 0 10px 0', padding: '0', fontSize: '14px' }}><span style={{ display: 'block', fontWeight: 'bold', fontSize: '13px' }}>Adresa</span> {customerInfo.address}, {customerInfo.city}, Srbija</p>
              
            </td>
            <td style={{ width: '50%', padding: '20px', verticalAlign: 'top' }}>
            </td>
          </tr>
          <tr>
            <td colSpan="2" style={{ fontSize: '20px', padding: '30px 15px 0 15px' }}>Proizvodi:</td>
          </tr>
          <tr>
            <td colSpan="2" style={{ padding: '15px' }}>
              {items.map((item, index) => (
                <p key={index} style={{ fontSize: '14px', margin: '0', padding: '10px', border: 'solid 1px #ddd', fontWeight: 'bold' }}>
                  <span style={{ display: 'block', fontSize: '13px', fontWeight: 'normal' }}>{item.name}</span>
                  <span style={{ display: 'block', fontSize: '13px', fontWeight: 'normal' }}>Šifra: {item.code}</span>
                  <span style={{ display: 'block', fontSize: '13px', fontWeight: 'normal' }}>Količina: {item.quantity}</span>
				  <span style={{ display: 'block', fontSize: '13px', fontWeight: 'normal' }}>Cena: ???</span>
                </p>
              ))}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="2" style={{ fontSize: '14px', padding: '50px 15px 0 15px' }}>
              <strong style={{ display: 'block', margin: '0 0 10px 0' }}>OD:</strong> Kristina Petronijević<br /> Novi Sad, Srbija<br /><br />
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  );
};

export default Invoice;
