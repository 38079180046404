import React from "react";

import './NotFound.css'

import LogoIcon from '../resources/LogoIcon.png';

function NotFound(){
    return(
        <div className="page-not-found">
            <img style={{width: '100px'}} src={LogoIcon} alt="logo-icon"/>
            <p>404 NOT FOUND</p>
        </div>
    );
}

export default NotFound;