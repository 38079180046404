import React from "react";

import './burgerButton.css';

function BurgerButton ({onClick, className}){
    return[
        <button id="b-button" className={className} onClick={onClick}>
            <div key={'line2'} className="line"></div>
            <div key={'line0'} className="line"></div>
            <div key={'line1'} className="line"></div>
        </button>
    ]
}

export default BurgerButton;