import React, { useEffect, useRef, useState } from "react";
import "./SocialIcon.css";

function SocialIcon({ href, imgSrc, name }) {
	const ref = useRef(null);

	const [Expanded, setExpanded] = useState(false);
	const [WindowWidth, setWindowWidth] = useState(window.innerWidth);

	const handleMouseEnter = () => {
		setExpanded(true);
	};

	const handleMouseLeave = () => {
		setExpanded(false);
	};

	useEffect(() => {
        // Access the DOM element using the ref
		const myElement = ref.current;
		if(WindowWidth > 789){
			if (myElement) {
				myElement.style.width = Expanded ? `${myElement.scrollWidth + 10}px` : '40px';
			}
		}else{
			if (myElement) {
				myElement.style.width = `${myElement.scrollWidth + 10}px`;
			}
		}
    },[Expanded]);

	function handleResize(){
		let newWidth = window.innerWidth;
		setWindowWidth(newWidth);
		newWidth > 789? setExpanded(false) : setExpanded(true);
	}

	useEffect(() => {
        // Attach the event listener when the component mounts
        window.addEventListener('resize', handleResize);
    
        // Optionally, you can also remove the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

	return (
		<a
		ref={ref}
		onMouseEnter={handleMouseEnter}
		onMouseLeave={handleMouseLeave}
		target="_blank"
		rel="noreferrer"
		href={href}
		className={`social-icon-link`}
		>
		<img src={imgSrc} alt="social-icon" />
		<span className="text-reveal">{name}</span>
		</a>
	);
}

export default SocialIcon;
