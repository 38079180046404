import React, { useState, useEffect } from "react";

import './loading.css';

import LOGO from '../resources/LogoIcon.png';

function Loading({promise}){

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
          try {
            // Wait for the provided promise to resolve
            await promise;
    
            // Simulate additional loading time if needed
            await new Promise(resolve => setTimeout(resolve, 1000));
    
            document.body.style.overflow = 'visible';
            setLoading(false);
          } catch (error) {
            console.error("Error during loading:", error);
            // Handle error or update state accordingly
            setLoading(false);
          }
        };
    
        fetchData();
    }, [promise]);

    return (
        <div className={loading? "loading-comp" : "loading-comp hidden"}>
            <div>
                <img src={LOGO} alt="logo"/>
                <p>UČITAVANJE</p>
            </div>
        </div>
    )
}

export default Loading;