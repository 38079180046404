
import React, { useState ,useEffect } from "react";
import { backendURL } from "../backendURL";

import CanSVG from '../resources/can.svg';
import Arrow from '../resources/arrow.svg';

import FarmasiIMG from '../resources/FarmasiSlika.jpg';
import FarmasiIMG2 from '../resources/FarmasiSlika2.jpg';
import FarmasiIMG3 from '../resources/FarmasiSlika3.JPG';

import './Shop.css'
import Invoice from "../components/invoice";

import LOGO from '../resources/LogoIcon.png';

const reactDOMserver = require('react-dom/server');

function Shop(){

    const [sendingEmail, setSendingEmail] = useState({sending: false, sent: false, failed: false});
    
    const [BrochureLink, setBrochureLink] = useState('');

    //Product inputs
    const [ProductName, setProductName] = useState('');
    const [ProductCode, setProductCode] = useState('');

    //Order confirmation inputs
    const [Name, setName] = useState('');
    const [Email, setEmail] = useState('');
    const [Address, setAddress] = useState('');
    const [City, setCity] = useState('');
    const [Phone, setPhone] = useState('');

    const [CartList, setCartList] = useState([]);
    const [OrderNumber, setOrderNumber] = useState('');

    const HandleDate = () => {
        var year , month;
        const date = new Date();
        month = date.getMonth() + 1;
        month = month < 10? `0${month}` : `${month}`;
        year = `${date.getFullYear()}`;
        const lastTwoDigitsOfYear = year.slice(-2);
        setBrochureLink(`https://s3-eu-west-1.amazonaws.com/test-farmasi-bucket/company/2/catalogue/F${lastTwoDigitsOfYear}${month}..pdf`);
    }

    const LoadCart = () => {
        const cart = localStorage.getItem('kika-farmasi-cart');
        if(cart){
            const cartJSON = JSON.parse(cart);
            setCartList(cartJSON)
        }
    }

    const addToCart = () => {
        if(ProductName !== '' && ProductCode.length > 5)
        {
            const cartJSONString = localStorage.getItem('kika-farmasi-cart');
            if(cartJSONString){
                var cart = JSON.parse(cartJSONString);
                const newEntry = {name: ProductName, code: ProductCode , quantity: 1};
                let productAdded = false;
                cart.forEach(el => {
                    if(el.code === newEntry.code){
                        if(!productAdded){
                            productAdded = true;
                            el.quantity += 1;
                        }
                    }
                })
                if(!productAdded){
                    cart.push(newEntry);
                }
                const cartString = JSON.stringify(cart);
                localStorage.setItem('kika-farmasi-cart', cartString);
            }else{
                var cart = [];
                const newEntry = {name: ProductName, code: ProductCode, quantity: 1};
                cart.push(newEntry);
                const cartString = JSON.stringify(cart);
                localStorage.setItem('kika-farmasi-cart', cartString);
            }
            setProductCode('');
            setProductName('');
            LoadCart();
        }
    }

    const removeItem = (code) => {
        var cart = [];
        CartList.forEach(el => {
            if(el.code !== code){
                cart.push(el);
            }
        })
        const cartString = JSON.stringify(cart);
        localStorage.setItem('kika-farmasi-cart', cartString);
        LoadCart();
    }

    const sendOrder = async () => {
        setSendingEmail({ sending: true, sent: false, failed: false });
        fetchOrderNumber();
        let responseMSG = '';
        try {
            let request = new FormData();
            request.append('key', 'Order');
            const pack = {'name': Name, 'email': Email, 'invoice': generateInvoice()};
            request.append('package', JSON.stringify(pack));
            const questionsResponse = await fetch(backendURL, {
                method: 'POST',
                headers: {},
                body: request,
            });
            const response = await questionsResponse.json();
            responseMSG = response.msg;
            if (response.msg === 'fail') {
                throw response.data;
            } else if (response.msg === 'success') {
                setSendingEmail({ sending: false, sent: true, failed: false });
            }
        } catch (error) {
            setSendingEmail({ sending: false, sent: false, failed: true });
            console.error("Error fetching OrderNUM:", error);
        } finally {
            // This will be executed regardless of success or failure
            setName('');
            setEmail('');
            setAddress('');
            setCity('');
            setPhone('');
            if(responseMSG === 'success'){
                CartList.forEach(el => {
                    removeItem(el.code);
                });
                LoadCart();
            }
        }
    };
    
    const fetchOrderNumber = async () => {
		try {
			let request = new FormData();
			request.append('key', 'OrderNumber');
			const questionsResponse = await fetch(backendURL, {
				method: 'POST',
				headers: {
				},
				body: request,
			});
			const response = await questionsResponse.json();
			if(response.msg === 'fail'){
				if(response.data === 'no-data-found'){
					throw('NO DATA IN FILE!');
				}else if(response.data === 'file-error'){
					throw('NO FILE FOUND!');
				}else if(response.data === `bad-key`){
					throw('Bad key request!');
				}else{
					throw('UNKNOWN!');
				}
			}else if(response.msg === 'success'){
                // Extract the string value
                let orderNumberValue = response.data.orderID;

                // Convert the string to an integer, increment it, and convert it back to a string
                orderNumberValue = (parseInt(orderNumberValue, 10) + 1).toString().padStart(6, '0');

				setOrderNumber(orderNumberValue);

			}

		} catch (error) {
			console.error("Error fetching OrderNUM:", error);
		}
	};
  
    useEffect(() => {
        HandleDate();
        LoadCart();
		fetchOrderNumber();
	}, []);
    
    const handleValueChange = (event, code) => {
        setCartList((prevCartList) => {
            const updatedCart = prevCartList
                .map((item) => {
                if (item.code === code) {
                    if (event === 'up') {
                    return { ...item, quantity: item.quantity + 1 };
                    } else if (event === 'down' && item.quantity > 0) {
                    return { ...item, quantity: item.quantity - 1 };
                    }
                }
                return item;
                })
                .filter((item) => item.quantity > 0); // Remove items with quantity 0
        
            // Update local storage
            const cartString = JSON.stringify(updatedCart);
            localStorage.setItem('kika-farmasi-cart', cartString);
        
            return updatedCart;
        });
    };

    function getCurrentDate() {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1; // Months are zero-indexed
        const year = today.getFullYear();
      
        // Pad single-digit day and month with leading zeros
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;
      
        // Create the dd.mm.yyyy format
        const formattedDate = `${formattedDay}.${formattedMonth}.${year}.`;
      
        return formattedDate;
      }
      
    function generateInvoice(){
        return reactDOMserver.renderToString(<Invoice invoiceNumber={OrderNumber} invoiceDate={getCurrentDate()} customerInfo={{name: Name, email: Email, address: Address, city: City, phone: Phone }} items={CartList} totalAmount={0}/>);
    }


    return(
        <div className="page-shop">
            <h1 className="shop-title">KIKA FARMASI SHOP</h1>
            <div className="farmasi-images">
                <img className="farmasi-image" src={FarmasiIMG} alt="farmasi-img" />
                <img className="farmasi-image" src={FarmasiIMG2} alt="farmasi-img" />
                <img className="farmasi-image" src={FarmasiIMG3} alt="farmasi-img" />
            </div>
            <a href={BrochureLink} target="_blank" rel="noreferrer" className="brochure-link">POGLEDAJTE KATALOG</a>
            <a href="https://www.farmasi.rs/index.php/nutriplus/nutriplus-brosura#book/" target="_blank" rel="noreferrer" className="brochure-link">POGLEDAJTE NutriPlus PONUDU</a>
            <div className="shop-section">
                <h2 className="shop-title">Porucite od Kristine</h2>
                        <div className="help-info">
                            <h2>Kako funkcioniše poručivanje:</h2>
                            <ol>
                                <li>
                                    Unesete željene proizvode sa šifrom i imenom.
                                </li>
                                <li>
                                    Kada ste zadovoljni sa svojom porudžbinom pošaljite porudžbinu.
                                </li>
                                <li>
                                    Posle poručivanja dobičete obaveštenje da je vaša porudžbina u obradi i u roku do 24 časa dobijate mejl sa vašom porudžbinom i izračunatim cenama.
                                </li>
                                <li>
                                    Pratite uputstva na tom email-u za potvrdu ili otkazivanje porudžbine.
                                </li>
                                <li>
                                    Ukoliko ste potvrdili porudžbinu dobijate mejl zahvalnice i kada proizvodi stignu od FARMASI kompanije dobijate obaveštenje da su proizvodi poslati na vašu adresu.
                                </li>
                                <li>
                                    Troškove dostave plaćate vi i proizvode plaćate tek kada stignu do vas.
                                </li>
                                <li>
                                    Ukoliko imate bilo kakva dodatna pitanja kontaktirajte me putem kontakt podataka websajta.
                                </li>
                                <li>
                                    Takodje možete poručiti i putem telefona koji se nalazi na kontakt stranici websajta.
                                </li>
                            </ol>
                        </div>
                <div className="shop-input">
                    <label htmlFor="product-input">NAZIV PROIZVODA</label>
                    <input onChange={(e) => setProductName(e.target.value)} value={ProductName} type="text" id="product-input" placeholder="Primer: BB Matte Lipstick"/>
                </div>
                <div className="shop-input">
                    <label htmlFor="code-input">ŠIFRA PROIZVODA</label>
                    <input onChange={(e) => setProductCode(e.target.value)} value={ProductCode} type="text" id="code-input" placeholder="Primer: 1000328"/>
                </div>
                <button className="brochure-link" onClick={() => addToCart()}>DODAJ</button>
            </div>
            <div className="shop-cart-display">
                <h3 className="shop-title">KORPA ({CartList.length})</h3>
                <div className="products-cart">
                {CartList.length !== 0? (
                        CartList.map((product, index) => (
                            <div className="product-section">
                                PROIZVOD: 
                                <div className="product-info">
                                <p key={`cart-product-name${index}`}>{product.name}</p>
                                <p>-</p>
                                <p key={`cart-product-code${index}`}>{product.code}</p>
                                <p className="quantity-p"  key={`cart-product-quantity${index}`}>
                                    <button className="quantity less" onClick={() => handleValueChange('down', product.code)}>
                                        <img src={Arrow} alt="arrow-svg" />
                                    </button>
                                    {product.quantity}
                                    <button className="quantity more" onClick={() => handleValueChange('up', product.code)}>
                                        <img src={Arrow} alt="arrow-svg" />
                                    </button>
                                    <button onClick={() => removeItem(product.code)} className="remove-button" key={`cart-product-remove${index}`}>
                                        <img src={CanSVG} alt="can-svg"  />
                                    </button>
                                </p>
                                </div>
                            </div>
                        ))
                ) : (
                    <p>Korpa vam je prazna</p>
                )}
                </div>
                {sendingEmail.sending? (
                    <div className="loading-comp">
                        <div>
                            <img src={LOGO} alt="logo"/>
                            <p>SLANJE</p>
                        </div>
                    </div>
                ) : sendingEmail.failed? (
                    <div className="loading-comp">
                        <div>
                            <img className="no-rotate" src={LOGO} alt="logo"/>
                            <p>DOŠLO JE DO GREŠKE!</p>
                            <button className="loading-comp-button" onClick={()=>setSendingEmail({sending: false, sent: false, failed: false})}>OK</button>
                        </div>
                    </div>
                ) :sendingEmail.sent? (
                    <div className="loading-comp">
                        <div>
                            <img className="no-rotate" src={LOGO} alt="logo"/>
                            <p>USPEŠNO! Očekujte odgovor uskoro!</p>
                            <button className="loading-comp-button" onClick={()=>setSendingEmail({sending: false, sent: false, failed: false})}>OK</button>
                        </div>
                    </div>
                ) : (
                    <div className="confirm-order">
                        <div className="shop-input">
                            <label htmlFor="order-name">IME I PREZIME</label>
                            <input onChange={(e) => setName(e.target.value)} type="text" id="order-name" />
                        </div>
                        <div className="shop-input">
                            <label htmlFor="order-email">EMAIL</label>
                            <input onChange={(e) => setEmail(e.target.value)} type="text" id="order-email" />
                        </div>
                        <div className="shop-input">
                            <label htmlFor="order-adress">ADRESA</label>
                            <input onChange={(e) => setAddress(e.target.value)} type="address" id="order-adress" />
                        </div>
                        <div className="shop-input">
                            <label htmlFor="order-city">GRAD</label>
                            <input onChange={(e) => setCity(e.target.value)} type="text" id="order-city" />
                        </div>
                        <div className="shop-input">
                            <label htmlFor="order-phone">TELEFON</label>
                            <input onChange={(e) => setPhone(e.target.value)} type="text" id="order-phone" />
                        </div>
                        <Invoice invoiceNumber={OrderNumber} invoiceDate={getCurrentDate()} customerInfo={{name: Name, email: Email, address: Address, city: City, phone: Phone }} items={CartList} totalAmount={0}/>
                        <button type="button" className="brochure-link" onClick={sendOrder}>
                            PORUČI
                        </button>
                    </div>
                )}
            </div>
        </div>
    ) 
}

export default Shop;