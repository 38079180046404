import React from "react";

import './home.css';

import HomeHeading from "../components/homeheading";
import HomeContents from "../components/homecontents";

function HomePage () {
    return(
        <div className="home">
            <HomeHeading/>
            <HomeContents/>
        </div>
    )
}

export default HomePage;