import React , {useState, useEffect, useRef} from "react";
import { backendURL } from "../backendURL";

import './Membership.css'

import Arrow from '../resources/arrow.svg';

import KikaIMG from '../resources/Kika.png';

import LOGO from '../resources/LogoIcon.png'

import RegistrationExplanation from "../components/MemberInstructions";

function Membership(){

    const [UserInput,setUserInput] = useState({name: "", email: ""});

    const [sendingEmail, setSendingEmail] = useState({sending: false, sent: false, failed: false});

    const [Expanded, setExpanded] = useState(false);

    const moreRef = useRef(null);
    
    function handleExpanded(ref){
        ref.current.style.height = !Expanded? '0px' : `${ref.current.scrollHeight}px`;
    }


    useEffect(()=>{
        handleExpanded(moreRef);
    }, [Expanded])

    function handleUserInput(e){
        const { name, value } = e.target;
        setUserInput(prevState => ({ ...prevState, [name]: value }));
    }

    const sendRequest = async () => {
        window.scrollTo(0,0);
        setSendingEmail({sending: true, sent: false, failed: false});
		try {
			let request = new FormData();
			request.append('key', 'ClubEmail');
            request.append('package', JSON.stringify(UserInput));
			const questionsResponse = await fetch(backendURL, {
				method: 'POST',
				headers: {
				},
				body: request,
			});
            if (!questionsResponse.ok) {
                console.error("Server returned an error:", questionsResponse.statusText);
            } else {
                // Handle the JSON response
                const response = await questionsResponse.json();
                if(response.msg === 'fail'){
                    const error = response.data;
                    throw(error);
                }else if(response.msg === 'success'){
                    if(response.data != null){
                        console.error("CLUB REQUEST ERROR:", response.data);
                    }
                    setSendingEmail({sending: false, sent: true, failed: false});
                    
                }
            }

		} catch (error) {
			console.error("CLUB REQUEST ERROR:", error);
            setSendingEmail({sending: false, sent: false, failed: true});
		}
        setUserInput({ name: '', email: '' });
	};

    return(
        <div className="page-membership">
            <h1 className="membership-title">
                Dobrodošli u Farmasi Klub!
            </h1>
            <p className="membership-info">
                Farmasi nije samo brend, već i zajednica strastvenih pojedinaca koji dele istu viziju - brigu o lepoti i zdravlju. Ako ste i vi zainteresovani da se pridružite našem timu, to je veoma jednostavno. Postanite deo Farmasi Kluba i započnite svoje putovanje ka uspehu.
            </p>
            <button className={Expanded?"membership-more-button expanded": "membership-more-button"} onClick={() => setExpanded(!Expanded)}>
                Kako postati član?
                <img className={Expanded?"membership-arrow expanded": "membership-arrow"} src={Arrow} alt="arrow"/>
            </button>
            <div className="membership-more" ref={moreRef}>
                <p>
                    Članstvo u Farmasi Klubu donosi brojne beneficije, uključujući pristup najnovijim proizvodima, ekskluzivnim događajima i podršku stručnjaka iz oblasti lepote. Vaša strast i posvećenost mogu vam pomoći da ostvarite svoje ciljeve.
                </p>
                <p>
                    Spremni ste da se pridružite Farmasi timu? Pošaljite nam zahtev za članstvo i otvorite vrata ka novim mogućnostima. Radujemo se što ćete postati deo naše Farmasi porodice.
                </p>
                <RegistrationExplanation/>
            </div>
            <img className="kika-img-member" src={KikaIMG} alt="kika"/>
            {sendingEmail.sending? (
                <div className="loading-comp">
                    <div>
                        <img src={LOGO} alt="logo"/>
                        <p>SLANJE</p>
                    </div>
                </div>
            ) : sendingEmail.failed? (
                <div className="loading-comp">
                    <div>
                        <img className="no-rotate" src={LOGO} alt="logo"/>
                        <p>DOŠLO JE DO GREŠKE!</p>
                        <button className="loading-comp-button" onClick={()=>setSendingEmail({sending: false, sent: false, failed: false})}>OK</button>
                    </div>
                </div>
            ) :sendingEmail.sent? (
                <div className="loading-comp">
                    <div>
                        <img className="no-rotate" src={LOGO} alt="logo"/>
                        <p>USPEŠNO!</p>
                        <button className="loading-comp-button" onClick={()=>setSendingEmail({sending: false, sent: false, failed: false})}>OK</button>
                    </div>
                </div>
            ) : (
                <div className="membership-contact-input">
                    <h2 className="membership-title">POŠALJI ZAHTEV</h2>
                    <label htmlFor="membership-name">
                        <img className={"membership-arrow"} src={Arrow} alt="arrow"/>
                        VAŠE IME
                        <img className={"membership-arrow"} src={Arrow} alt="arrow"/>
                    </label>
                    <input name="name" value={UserInput.name} id="membership-name" placeholder="Vaše Ime" onChange={handleUserInput}/>
                    <label htmlFor="membership-email">
                        <img className={"membership-arrow"} src={Arrow} alt="arrow"/>
                        VAŠA EMAIL ADRESA
                        <img className={"membership-arrow"} src={Arrow} alt="arrow"/>
                    </label>
                    <input name="email" value={UserInput.email} id="membership-email" placeholder="vaš.email@example.com" onChange={handleUserInput}/>
                    <button className="membership-submit-button" onClick={() => sendRequest()}>
                        POŠALJI
                    </button>
                </div>
            )}
            </div>
    )
}

export default Membership;